import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import UniversalCookie from 'universal-cookie';
import { Client, Provider as UrqlProvider } from 'urql';

import { ContextsProvider } from './context/ContextsProvider';
import { FavouritesStoreUpdater } from '@Components/Favourites/FavouritesStoreUpdater';
import TranslationProvider from '@Contexts/TranslationProvider';
import { NavigationInterceptor } from '@Core/NavigationInterceptor';
import { PageLayout } from '@Pages/PageLayout';
import { RouterSwitch } from '@Pages/RouterSwitch';
import { Contexts } from '@Server/handlers/handle-contexts/types';
import { StoreContextProvider } from '@Stores/StoreContext';
import '@Dates/initialiseDayjs';

interface AppProps {
  contexts: Contexts;
  helmetContext?: any;
  cookies: UniversalCookie;
  urqlClient: Client;
}

export const App: React.FC<AppProps> = ({ contexts, helmetContext = {}, cookies, urqlClient }) => {
  const { locale } = contexts.App.siteOverride || contexts.App.site;

  return (
    <UrqlProvider value={urqlClient}>
      <ContextsProvider
        contexts={contexts}
        cookies={cookies}
      >
        <TranslationProvider locale={locale}>
          <HelmetProvider context={helmetContext}>
            <NavigationInterceptor />
            <StoreContextProvider>
              <FavouritesStoreUpdater>
                <PageLayout>
                  <RouterSwitch />
                </PageLayout>
              </FavouritesStoreUpdater>
            </StoreContextProvider>
          </HelmetProvider>
        </TranslationProvider>
      </ContextsProvider>
    </UrqlProvider>
  );
};
